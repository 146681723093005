.App {
  text-align: center;
  /* background-color: #e3eef3; */
  min-height: 100vh;
}

body {
  max-width: 100%;
  margin: 0 auto;
}

#root {
  min-height: 100vh;
}

/******************************************************************************/
/* Navbar */
.appNav {
  margin: 0 16px 0 0;
}

.rightNav {
  float: right;
}

.pageButtonBold {
  font-weight: 800;
}

.buttonContainer {
  background-color: #fff;
  border-radius: 16px;
  display: inline-block;
}

.buttonContainerTop {
  margin-left: 175px;
}

.connectButton {
  background-color: rgb(253, 234, 241) !important;
  border-radius: 16px !important;
  border: 1px solid #fff !important;
  color: rgb(213, 0, 102) !important;
}

.connectButtonContainer {
  display: inline-block;
  margin-right: 10px;
}

.connectButton:hover {
  border-color: rgb(213, 0, 102) !important;
}

.hoverBold:hover {
  font-weight: 800;
}

/******************************************************************************/
/* Swap Container */
.appBody {
  height: 100%;
  padding-top: 80px;
}

.swapContainer {
  background-color: rgb(203, 231, 240);
  width: 440px;
  height: 350px;
  margin: 0 auto;
  top: 50%;
  border-radius: 24px;
  border: 1px solid #fff;
  padding: 6px;
}

.swapHeader {
  text-align: left;
  padding: 4px 20px 0px 20px;
}

.swapText {
  color: #000;
  font-size: 20px;
}

.gearContainer {
  float: right;
  font-size: 22px
}

/******************************************************************************/
/* Currency Input */
.currencyInput {
  background-color: rgb(217, 236, 242);
  margin: 10px !important;
  padding: 10px;
  border-radius: 20px;
}
.currencyInput:hover {
  border-color: #65cdee !important;
  border: 1px solid;
}
::placeholder {
  opacity: 0.25;
}
.numberContainer {
  text-align: left;
}
.spinnerContainer {
  height: 55px;
  line-height: 55px;
}
.currencyInputField {
  font-size: 28px;
  display: inline-block;
  width: 100%;
  border: none;
  background-color: rgb(217, 236, 242);
}
.tokenContainer {
  text-align: right;
}
.tokenName {
  font-size: 28px;
  display: block;
  color: #000;
}
.balanceContainer {
  display: block;
}
.balanceAmount {
  width: 100%;
  font-size: 16px;
  display: inline;
  color: #000;
}

/******************************************************************************/
/* Ratio */
.ratioContainer {
  text-align: left;
  margin-left: 10px;
  height: 28px;
}

.feeContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 3px 10px 3px 10px;
}

.tooltip {
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  display: none; /* Hide initially */
  z-index: 1;
}




/* .rateFooter {
  border: rgb(213, 0, 102) solid 1px;
  border-radius: 6px;
  width: 350px;
  margin-left: 13px;
} */

.rateFooter {
  border: rgb(213, 0, 102) solid 1px;
  border-radius: 6px;
  width: 350px;
  margin: 0 auto; 
}


.feeBorder {
  border: solid red 1px;
  border-radius: 8px;
  width: 320px;
  /* flex-direction: row;
  justify-content: space-between; */
}
.handleRateInfo {
  /* border: solid red 1px;
  border-radius: 8px;
  width: 320px; */
  flex-direction: row;
  justify-content: space-between;
}


/******************************************************************************/
/* Swap Button */
.swapButtonContainer {
  margin: 10px;
}
.tradeButton {
  width: 100%;
  height: 55px;
  line-height: 55px;
  font-size: 24px;
  color: #fff;
  border-radius: 20px;
  background-color: #dd2f81;
  cursor: pointer;
  border: 1px solid #dd2f81;
}
.tradeButton-disabled {
  width: 100%;
  height: 55px;
  line-height: 55px;
  font-size: 24px;
  color: #fff;
  border-radius: 20px;
  background-color: #cccacb;
  cursor: pointer;
  border: 1px solid #cccacb;
  margin-top: 10px;
}

/******************************************************************************/
/* Config Modal */

.modaly {
  position: fixed;
  left: 0;
  top: -510px;
  right: -110px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(86, 90, 105);

}

.modal-content {
  background-color: #fff;
  width: 300px !important;
  padding: 0px 15px;
  background-color: rgb(237, 238, 242) !important;
  border: 1px solid rgb(206, 208, 217) !important;
  border-radius: 12px !important;
}

.modalTitle {
  margin: auto;
}

.titleHeader {
  margin-left: -12px;
}
.labelField {
  padding-left: 0px !important;
}
.fieldContainer {
  padding-left: 0px !important;
}
.inputField {
  padding-left: 10px;
  border-radius: 36px;
  border: 1px solid #65cdee;
  height: 2rem;
  margin: 10px 0;
  width: 100%;
}
.inputFieldUnitsContainer {
  padding-left: 0px !important;
  padding-top: 14px;
}






.toggle-button-container {
  width: 200px; 
  margin-bottom: 30px;
  position: relative;
  left: 43%;
  top: 70px;
  
}

@media screen and (max-width: 600px) {
  .toggle-button-container {
    width: 200px; 
    margin-bottom: 30px;
    position: relative;
    left: 35%;
    top: 70px;
    
  }
}

.toggle-button {
  flex: 1;
  padding: 10px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s;
  border: 1px solid lightgray;
  border-radius: 2px;
  width: 90px;
}

.toggle-button.active {
  background-color: rgb(213, 0, 102); /* Set the active background color */
  color: #fff;
}

.toggle-button:hover {
  background-color: lightgray;
}


.updown-button {
  color: black;
  border: 2px solid black;
  position: relative;
  top: -110px;
  border-radius: 50px;
}